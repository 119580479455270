import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes and Route
import Home from './pages/Home'; // Import your Home component
import RegisterPage from './pages/RegisterPage'; // Import the RegisterPage component
import IndividualPage from './pages/IndividualPage'; // Import the IndividualPage component
import OrganizationPage from './pages/OrganizationPage'; // Import the OrganizationPage component
import QuotePage from './pages/QuotePage'; // Import the QuotePage component
import Layout from './components/Layout'; // Import the Layout component
import QuoteDetailsPage from './pages/QuoteDetailsPage';
import PaymentPage from './pages/PaymentPage';


function App() {
  return (
    <Router>
      {/* The Layout component wraps all routes, ensuring Header and Footer are displayed on each page */}
      <Layout>
        {/* Routes component to define all routes */}
        <Routes>
          {/* Route for the homepage */}
          <Route path="/" element={<Home />} />
          
          {/* Route for the registration page */}
          <Route path="/register" element={<RegisterPage />} />

          {/* Route for the individual page */}
          <Route path="/individualPage" element={<IndividualPage />} />

          {/* Route for the organization page */}
          <Route path="/organizationPage" element={<OrganizationPage />} />

          {/* Route for the quote page */}
          <Route path="/quotePage" element={<QuotePage />} />

          {/* Route for the quote details page */}
          <Route path="/quoteDetails" element={<QuoteDetailsPage />} />

          {/* Route for the payment page */}
          <Route path="/payment" element={<PaymentPage />} />
          
          {/* Add more routes as needed */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
