import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import ServicesSection from '../components/ServicesSection';
import PricingSection from '../components/PricingSection';
import Footer from '../components/Footer';
import LoginCard from '../components/LoginCard';

// Custom hook to parse query parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home() {
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const query = useQuery();
  const navigate = useNavigate(); // To navigate programmatically

  useEffect(() => {
    const loginQueryParam = query.get('login');
    if (loginQueryParam === 'true') {
      setIsLoginVisible(true); // Show login modal if query parameter is true
    } else {
      setIsLoginVisible(false); // Hide login modal if query parameter is not present
    }
  }, [query]);

  // Function to close the login modal and remove the query parameter from the URL
  const handleCloseLogin = () => {
    setIsLoginVisible(false); // Set state to false, which hides the login modal
    navigate('/'); // Navigate to the home page without query parameters
  };

  return (
    <>
      <HeroSection />
      <ServicesSection />
      <PricingSection />
      {/* Pass the handleCloseLogin function to the LoginCard as the onClose prop */}
      {isLoginVisible && <LoginCard isVisible={isLoginVisible} onClose={handleCloseLogin} />}
    </>
  );
}

export default Home;
