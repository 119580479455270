import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';  // Import config for URLs
import Cookies from 'js-cookie'; // For secure cookie handling

function LoginCard({ isVisible, onClose, onLoginSuccess }) {
  const [email, setEmail] = useState('');  // Email state
  const [password, setPassword] = useState('');  // Password state
  const [error, setError] = useState('');  // Error message state
  const [location, setLocation] = useState('');  // Location state
  const [isLoading, setIsLoading] = useState(false); // Loading state to prevent multiple submissions

  const navigate = useNavigate();  // Hook for navigation

  // Function to get user's current location using Geolocation API
  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation(`${latitude}, ${longitude}`);
        console.log(`Location fetched: ${latitude}, ${longitude}`);  // Log the fetched location
      }, (error) => {
        console.error('Error getting location:', error);
        setLocation('Unknown location');
      });
    } else {
      setLocation('Location not supported');
    }
  };

  useEffect(() => {
    fetchLocation();  // Get location when the component mounts
  }, []);

  // Function to generate current timestamp in the required format
  const getCurrentTimestamp = () => {
    const timestamp = new Date().toISOString();  // ISO 8601 format for timestamp
    console.log(`Current timestamp generated: ${timestamp}`);
    return timestamp;
  };

  // Function to handle the login form submission
  const handleLogin = async (e) => {
    e.preventDefault();  // Prevent the default form submission

    // Validation checks
    if (!email || !password) {
      setError('Please fill in both fields.');
      toast.error('Please fill in both fields.');
      console.log('Validation failed: Missing email or password');
      return;
    }

    setIsLoading(true);  // Set loading state to true to prevent multiple clicks

    // Log the login details
    console.log('Attempting to login with:');
    console.log(`Email: ${email}`);
    console.log(`Password: [HIDDEN]`);
    console.log(`Timestamp: ${getCurrentTimestamp()}`);
    console.log(`Location: ${location}`);

    try {
      // Prepare login data for the request
      const loginData = {
        email: email,
        password: password,
        timestamp: getCurrentTimestamp(),
        location: location || 'NS, Canada',
      };

      const headers = {
        'accept': 'application/json',
        'X-Real-IP': '203.0.113.195',
        'X-Forwarded-For': '203.0.113.195',
        'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36',
        'X-Request-ID': 'unique-request-id',
        'Content-Type': 'application/json',
      };

      // Log the request headers and data
      console.log('Request headers:', headers);
      console.log('Login data being sent:', loginData);

      // Send POST request to the login endpoint
      const response = await fetch(`${config.usermanagement.host}:${config.usermanagement.port}${config.usermanagement.base_url}${config.usermanagement.login}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(loginData),
      });

      const result = await response.json();
      console.log('API response:', result);  // Log the full response

      if (response.ok) {
        // Append additional fields to response data
        // result.userId = '1';
        // result.userType = result.userType || 'organization';
        // result.userStatus = result.userStatus || 'pendingProfileCompletion';

        console.log('Modified API response:', result);  // Log the modified response

        // Save tokens and user details securely in cookies
        Cookies.set('accessToken', result.accessToken, { secure: true, sameSite: 'strict' });
        Cookies.set('refreshToken', result.refreshToken, { secure: true, sameSite: 'strict' });
        Cookies.set('tokenExpiration', result.tokenExpiration, { secure: true, sameSite: 'strict' });
        Cookies.set('userId', result.userId, { secure: true, sameSite: 'strict' });

        // Show success toast
        toast.success('Login successful! Redirecting...', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log('Login successful:', result);

        // Call the prop function to update login state in Header
        onLoginSuccess();

        // Handle redirection based on userStatus and userType
        if (result.userStatus === 'active') {
          setTimeout(() => {
            navigate('/');
          }, 3000);  // Redirect to home page if status is active
        } else if (result.userStatus === 'pendingProfileCompletion') {
          if (result.userType === 'individual') {
            setTimeout(() => {
              navigate('/individualPage');
            }, 3000);  // Redirect to individualPage if userType is individual
          } else if (result.userType === 'organization') {
            setTimeout(() => {
              navigate('/organizationPage');
            }, 3000);  // Redirect to organizationPage if userType is organization
          }
        }
      } else {
        // Login failed
        setError(result.message || 'Login failed, please try again.');
        toast.error(result.message || 'Login failed, please try again.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log('Login failed with error:', result.message);
        setIsLoading(false); // Reset loading state if login failed
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred during login. Please try again.');
      toast.error('An error occurred during login. Please try again.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsLoading(false);  // Reset loading state if there is an error
    }
  };

  // Function to handle redirection to the registration page
  const handleRegisterRedirect = () => {
    onClose();
    navigate('/register');  // Navigate to the registration page
  };

  return (
    <>
      {/* Toast container for showing messages */}
      <ToastContainer />

      {isVisible && !isLoading && <div className="overlay" onClick={onClose}></div>}
      {isVisible && !isLoading && (
        <div className="login-card fixed inset-0 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
            <div className="relative">
              <div className="text-center mb-6">
                <img src="/LOGO.png" alt="Maple Pro Courier Logo" className="h-12 mx-auto mb-4" />
                <h2 className="text-2xl font-bold text-gray-800">Login to Your Account</h2>
              </div>
              <button className="close-button absolute top-2 right-2 text-gray-600 hover:text-red-600 text-xl" onClick={onClose}>×</button>
            </div>

            <form onSubmit={handleLogin}>
              <input 
                type="text" 
                placeholder="Email/Username" 
                className="input-field mb-4 w-full p-2 border border-gray-300 rounded" 
                value={email}
                onChange={(e) => setEmail(e.target.value)} 
              />
              <input 
                type="password" 
                placeholder="Password" 
                className="input-field mb-4 w-full p-2 border border-gray-300 rounded" 
                value={password}
                onChange={(e) => setPassword(e.target.value)} 
              />
              {error && <p className="text-red-600 text-sm mb-4">{error}</p>}
              <button 
                type="submit" 
                className="submit-button bg-red-600 text-white py-2 px-4 rounded w-full"
                disabled={isLoading}  // Disable button during loading
              >
                {isLoading ? 'Logging in...' : 'Login'}
              </button>
            </form>

            <div className="text-center mt-4">
              <a href="#" className="text-gray-600 text-sm">Forgot password?</a>
            </div>
            <p className="mt-6 text-gray-600 text-center">
              Don’t have an account? 
              <button 
                onClick={handleRegisterRedirect} 
                className="text-red-600 font-semibold ml-1"
              >
                Register
              </button>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginCard;
