import React from 'react';
import Header from './Header'; // Import the Header component
import Footer from './Footer'; // Import the Footer component

// The Layout component wraps around the content of each page
// It includes the Header at the top and the Footer at the bottom
function Layout({ children }) {
  return (
    <div className="layout-container">
      <Header /> {/* The Header is displayed at the top of the page */}
      <main className="content">
        {children} {/* The children prop will render the content of each page */}
      </main>
      <Footer /> {/* The Footer is displayed at the bottom of the page */}
    </div>
  );
}

export default Layout;
