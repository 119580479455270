import React, { useState } from 'react';
import GetQuoteCard from './GetQuoteCard';

function HeroSection() {
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);

  // Open/close the quote modal
  const toggleQuoteModal = () => {
    setIsQuoteModalOpen(!isQuoteModalOpen);
  };

  return (
    <section className="bg-white text-center py-20">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        Delivering with Precision
      </h1>
      <p className="text-lg text-gray-600 mb-8">
        Fast, Reliable, and Secure Courier Services
      </p>
      <button className="bg-red-600 text-white py-2 px-6 rounded" onClick={toggleQuoteModal}>
        Get a Quote
      </button>

      {/* GetQuoteCard Modal */}
      {isQuoteModalOpen && (
        <GetQuoteCard isVisible={isQuoteModalOpen} onClose={toggleQuoteModal} />
      )}
    </section>
  );
}

export default HeroSection;
