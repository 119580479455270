import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import Cookies from 'js-cookie';
import LoginCard from './LoginCard';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginCardVisible, setIsLoginCardVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Check if user is logged in based on the presence of an accessToken cookie
  useEffect(() => {
    const token = Cookies.get('accessToken');
    setIsLoggedIn(!!token);
  }, []);

  // Handle logout
  const handleLogout = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    Cookies.remove('tokenExpiration');
    Cookies.remove('userId');
    setIsLoggedIn(false);
    navigate('/');
  };

  // Handle login success
  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setIsLoginCardVisible(false); // Close the login modal after successful login
  };

  return (
    <header className="bg-white shadow-md fixed top-0 left-0 w-full z-10">
      <div className="container mx-auto p-4 flex justify-between items-center">
        
        {/* Logo Section */}
        <div className="text-lg font-bold text-red-600 flex items-center">
          <Link to="/">
            <img src="/LOGO.png" alt="Maple Pro Courier Logo" className="h-8 w-8 mr-2" />
          </Link>
          Maple Pro Courier
        </div>

        {/* Desktop Navigation Bar */}
        <nav className="hidden md:flex space-x-6">
          <Link to="/" className="text-gray-700 hover:text-red-600">Home</Link>
          <Link to="/" className="text-gray-700 hover:text-red-600">Services</Link>
          <Link to="/" className="text-gray-700 hover:text-red-600">Track Your Package</Link>
          <Link to="/" className="text-gray-700 hover:text-red-600">Pricing</Link>
          <Link to="/" className="text-gray-700 hover:text-red-600">Contact Us</Link>
          {isLoggedIn ? (
            <button onClick={handleLogout} className="text-gray-700 hover:text-red-600">
              Logout
            </button>
          ) : (
            <button onClick={() => setIsLoginCardVisible(true)} className="text-gray-700 hover:text-red-600">
              Login
            </button>
          )}
        </nav>

        {/* Mobile Menu Toggle Button */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu}>
            {isMenuOpen ? (
              <AiOutlineClose className="text-2xl text-gray-700" />
            ) : (
              <AiOutlineMenu className="text-2xl text-gray-700" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      <div
        className={`fixed top-0 left-0 w-3/4 h-full bg-white shadow-lg z-50 transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out md:hidden`}
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <div className="text-lg font-bold text-red-600 flex items-center">
            <Link to="/">
              <img src="/LOGO.png" alt="Maple Pro Courier Logo" className="h-8 w-8 mr-2" />
            </Link>
            Maple Pro Courier
          </div>
          <button onClick={toggleMenu} className="text-gray-700 text-2xl">
            <AiOutlineClose />
          </button>
        </div>
        <ul className="flex flex-col items-start p-4 space-y-4">
          <li><Link to="/" onClick={toggleMenu} className="text-gray-700 hover:text-red-600">Home</Link></li>
          <li><Link to="/" onClick={toggleMenu} className="text-gray-700 hover:text-red-600">Services</Link></li>
          <li><Link to="/" onClick={toggleMenu} className="text-gray-700 hover:text-red-600">Track Your Package</Link></li>
          <li><Link to="/" onClick={toggleMenu} className="text-gray-700 hover:text-red-600">Pricing</Link></li>
          <li><Link to="/" onClick={toggleMenu} className="text-gray-700 hover:text-red-600">Contact Us</Link></li>
          <li>
            {isLoggedIn ? (
              <button onClick={handleLogout} className="text-gray-700 hover:text-red-600">
                Logout
              </button>
            ) : (
              <button onClick={() => {
                setIsLoginCardVisible(true);
                toggleMenu();
              }} className="text-gray-700 hover:text-red-600">
                Login
              </button>
            )}
          </li>
        </ul>
      </div>

      {/* Login Card Modal */}
      {isLoginCardVisible && (
        <LoginCard 
          isVisible={isLoginCardVisible} 
          onClose={() => setIsLoginCardVisible(false)} 
          onLoginSuccess={handleLoginSuccess} 
        />
      )}
    </header>
  );
}

export default Header;
