import React from 'react';

function ServicesSection() {
  const services = [
    { name: 'Same-Day Delivery', description: 'Guaranteed same-day delivery within your city.' },
    { name: 'Express Delivery', description: 'Rapid delivery for urgent shipments with guaranteed speed.' },
    { name: 'Package Tracking', description: 'Real-time tracking for peace of mind.' },
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index} className="text-center p-4">
            <h3 className="text-xl font-semibold text-gray-700">{service.name}</h3>
            <p className="text-gray-600 mt-2">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ServicesSection;
