import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';  // Importing toast components
import 'react-toastify/dist/ReactToastify.css';  // Importing toast CSS
import config from '../config';  // Importing API URL from the config file

function RegisterPage() {
  const [userEmail, setUserEmail] = useState(''); // Email
  const [accountType, setAccountType] = useState(''); // Type: Individual or Organization
  const [userPassword, setUserPassword] = useState(''); // Password
  const [confirmUserPassword, setConfirmUserPassword] = useState(''); // Confirm Password
  const [agreeToTerms, setAgreeToTerms] = useState(false); // Terms of Service Agreement
  const [consentForCommunication, setConsentForCommunication] = useState(false); // Communication Consent
  const [formError, setFormError] = useState(''); // Error message
  const [showTermsModal, setShowTermsModal] = useState(false); // State for terms and conditions modal

  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // Function to handle form submission (registration logic)
  const handleRegister = async (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log('Register button clicked'); // Debug log

    // Validation checks
    console.log('Validating form inputs...'); // Debug log
    if (!userEmail || !userPassword || !confirmUserPassword || !accountType) {
      setFormError('Please fill in all fields.');
      console.log('Validation failed: Missing fields'); // Debug log
      return;
    }

    if (userPassword !== confirmUserPassword) {
      setFormError('Passwords do not match.');
      console.log('Validation failed: Passwords do not match'); // Debug log
      return;
    }

    if (!agreeToTerms) {
      setFormError('You must agree to the terms and services.');
      console.log('Validation failed: Terms not agreed'); // Debug log
      return;
    }

    // Clear error messages
    setFormError('');

    // Prepare user data to send to the backend
    const userData = {
      email: userEmail,
      type: accountType,
      password: userPassword,
      tosAgreement: agreeToTerms,
      communicationConsent: consentForCommunication,
    };

    console.log('Form data:', userData); // Debug log

    try {
      console.log('Sending POST request to:', `${config.usermanagement.host}:${config.usermanagement.port}${config.usermanagement.base_url}${config.usermanagement.create_user}`); // Debug log
      // Send POST request to backend
      const response = await fetch(`${config.usermanagement.host}:${config.usermanagement.port}${config.usermanagement.base_url}${config.usermanagement.create_user}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const result = await response.json();

      console.log('API response:', result); // Debug log

      if (response.ok) {
        // Show success toast
        toast.success('Registered successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        console.log('Registration successful'); // Debug log
        // Redirect to the login page after 3 seconds
        setTimeout(() => navigate('/?login=true'), 3000);
      } else {
        // Handle error response and show error toast
        setFormError(result.message || 'Registration failed, please try again.');
        toast.error(result.message || 'Registration failed, please try again.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log('Registration failed with error:', result.message); // Debug log
      }
    } catch (error) {
      console.error('Error:', error); // Debug log
      setFormError('An error occurred. Please try again.');
      // Show error toast for catch block
      toast.error('An error occurred. Please try again.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // Function to toggle terms modal
  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  return (
    <div className="register-page container mx-auto p-8">
      <ToastContainer />  {/* Toast container for displaying messages */}
      <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Create Your Account</h2>
        {formError && <p className="text-red-600 text-sm mb-4">{formError}</p>}

        {/* Form Start */}
        <form onSubmit={handleRegister}>
          
          {/* Email Field */}
          <input
            type="email"
            placeholder="Email Address"
            className="input-field mb-4 w-full p-2 border border-gray-300 rounded"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />

          {/* Type Field (Dropdown with "User Type" placeholder) */}
          <select
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
            className="input-field mb-4 w-full p-2 boindividualrder border-gray-300 rounded text-gray-500"
          >
            <option value="" disabled selected hidden>User Type</option>
            <option value="individual">Individual</option>
            <option value="organization">Organization</option>
          </select>

          {/* Password Field */}
          <input
            type="password"
            placeholder="Password"
            className="input-field mb-4 w-full p-2 border border-gray-300 rounded"
            value={userPassword}
            onChange={(e) => setUserPassword(e.target.value)}
          />

          {/* Confirm Password Field */}
          <input
            type="password"
            placeholder="Confirm Password"
            className="input-field mb-4 w-full p-2 border border-gray-300 rounded"
            value={confirmUserPassword}
            onChange={(e) => setConfirmUserPassword(e.target.value)}
          />

          {/* Terms and Services Agreement */}
          <div className="mb-4 flex items-start">
            <input
              type="checkbox"
              id="agreeToTerms"
              checked={agreeToTerms}
              onChange={() => setAgreeToTerms(!agreeToTerms)}
              className="mr-2"
            />
            <label htmlFor="agreeToTerms" className="text-gray-600 text-sm">
              I agree to the 
              <button type="button" onClick={toggleTermsModal} className="text-red-600 font-semibold ml-1 underline">
                terms and services
              </button>
            </label>
          </div>

          {/* Communication Consent */}
          <div className="mb-4 flex items-start">
            <input
              type="checkbox"
              id="communicationConsent"
              checked={consentForCommunication}
              onChange={() => setConsentForCommunication(!consentForCommunication)}
              className="mr-2"
            />
            <label htmlFor="communicationConsent" className="text-gray-600 text-sm">
              I consent to receive promotional communication
            </label>
          </div>

          {/* Submit Button */}
          <button type="submit" className="submit-button bg-red-600 text-white py-2 px-4 rounded w-full">
            Register
          </button>
        </form>
        {/* Form End */}

        {/* Redirect to Login */}
        <p className="mt-6 text-gray-600 text-center">
          Already have an account?
          <button 
            onClick={() => navigate('/?login=true')} 
            className="text-red-600 font-semibold ml-1"
          >
            Login
          </button>
        </p>
      </div>

      {/* Terms and Conditions Modal */}
      {showTermsModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
            <p className="mb-4">
              {/* Insert the actual terms and conditions here */}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl vel scelerisque facilisis, nulla libero bibendum ligula, ut ultrices lacus libero eget leo. Aenean sit amet ligula vel erat facilisis pretium a et est.
            </p>
            <button 
              onClick={toggleTermsModal} 
              className="bg-red-600 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegisterPage;
