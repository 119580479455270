const config = {
  usermanagement: {
    host: 'http://usermanagement-auth-ms',
    port: '8080',
    base_url: '/usermanagement/auth',
    create_user: '/createuser',
    login: '/login',
  },

  userprofilemanagement: {
    host: 'http://usermanagement-profile-ms',
    port: '8080',
    base_url: '/usermanagement/profile',
    create_individual: '/individual',
    create_organization: '/organization',
  },

  ordermanagement: {
    host: 'http://ordermanagement-order-ms',
    port: '8080',
    base_url: '/ordermanagement',
    create_order: '/orders',

  },
    // Add other sections if needed
};
  
export default config;
  