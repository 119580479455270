import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config'; 

function QuotePage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Destructure package data from location state
  const { weight, length, width, height } = location.state || {};

  const [formData, setFormData] = useState({
    customerId: Cookies.get('userId') || '', // default customer ID from cookies
    customerContact: {
      name: '',
      phone: '',
      email: '',
    },
    useDefaultContact: true, // Flag for using default contact
    priorityDelivery: false, // checkbox
    pickup: {
      fullName: '',
      company: '',
      streetAddress: '',
      addressLine2: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
      phoneNumber: '',
    },
    dropoff: {
      fullName: '',
      company: '',
      streetAddress: '',
      addressLine2: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
      phoneNumber: '',
    },
    deliveryInstructions: '',
  });

  const [errors, setErrors] = useState({});

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    if (name.startsWith('pickup.') || name.startsWith('dropoff.')) {
      const [field, subField] = name.split('.');
      setFormData((prevState) => ({
        ...prevState,
        [field]: {
          ...prevState[field],
          [subField]: fieldValue,
        },
      }));
    } else if (name.startsWith('customerContact.')) {
      const [, subField] = name.split('.');
      setFormData((prevState) => ({
        ...prevState,
        customerContact: {
          ...prevState.customerContact,
          [subField]: fieldValue,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: fieldValue,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.pickup.fullName) newErrors.pickupFullName = 'Pickup Full Name is required';
    if (!formData.pickup.streetAddress) newErrors.pickupStreet = 'Pickup Street Address is required';
    if (!formData.pickup.city) newErrors.pickupCity = 'Pickup City is required';
    if (!formData.pickup.province) newErrors.pickupProvince = 'Pickup Province is required';
    if (!formData.pickup.postalCode) newErrors.pickupPostal = 'Pickup Postal Code is required';
    if (!formData.pickup.phoneNumber) newErrors.pickupPhone = 'Pickup Phone Number is required';

    if (!formData.dropoff.fullName) newErrors.dropoffFullName = 'Dropoff Full Name is required';
    if (!formData.dropoff.streetAddress) newErrors.dropoffStreet = 'Dropoff Street Address is required';
    if (!formData.dropoff.city) newErrors.dropoffCity = 'Dropoff City is required';
    if (!formData.dropoff.province) newErrors.dropoffProvince = 'Dropoff Province is required';
    if (!formData.dropoff.postalCode) newErrors.dropoffPostal = 'Dropoff Postal Code is required';
    if (!formData.dropoff.phoneNumber) newErrors.dropoffPhone = 'Dropoff Phone Number is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error('Please fill in the required fields.');
      return;
    }

    // Prepare the request data in the required format
    const requestData = {
      customerId: formData.customerId,
      priorityDelivery: formData.priorityDelivery,
      pickup: {
        address: {
          fullName: formData.pickup.fullName,
          company: formData.pickup.company,
          streetAddress: formData.pickup.streetAddress,
          addressLine2: formData.pickup.addressLine2,
          city: formData.pickup.city,
          province: formData.pickup.province,
          postalCode: formData.pickup.postalCode,
          country: formData.pickup.country,
          phoneNumber: formData.pickup.phoneNumber,
        },
      },
      dropoff: {
        address: {
          fullName: formData.dropoff.fullName,
          company: formData.dropoff.company,
          streetAddress: formData.dropoff.streetAddress,
          addressLine2: formData.dropoff.addressLine2,
          city: formData.dropoff.city,
          province: formData.dropoff.province,
          postalCode: formData.dropoff.postalCode,
          country: formData.dropoff.country,
          phoneNumber: formData.dropoff.phoneNumber,
        },
      },
      packageDetails: {
        weight,
        dimensions: {
          length,
          width,
          height,
        },
      },
      deliveryInstructions: formData.deliveryInstructions,
    };

    // Only add customerContact if useDefaultContact is false
    if (!formData.useDefaultContact) {
      requestData.customerContact = {
        name: formData.customerContact.name,
        phone: formData.customerContact.phone,
        email: formData.customerContact.email,
      };
    }

    console.log('Request Data:', requestData);

    try {
      const response = await fetch(`${config.ordermanagement.host}:${config.ordermanagement.port}${config.ordermanagement.base_url}${config.ordermanagement.create_order}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([requestData]), // Wrap in array as per the given format
      });

      // Log request and response details
      console.log('Request Headers:', {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      });
      console.log('Request Body:', JSON.stringify([requestData]));

      const result = await response.json();
      console.log('API Response:', result);

      if (response.ok) {
        toast.success('Quote submitted successfully!');
        // setTimeout(() => navigate('/'), 3000);
        navigate('/quoteDetails', { state: { pricing: result[0].pricing } });
      } else {
        toast.error(result.message || 'Failed to submit quote.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while submitting the quote.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-50 to-gray-100">
      <ToastContainer />
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full" style={{ marginBottom: '60px' }}>
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-6" style={{ marginTop: '60px' }}>Tell us more about the package</h1>

        <form onSubmit={handleSubmit}>
          {/* <h2 className="text-2xl font-bold text-gray-800 mb-4">Package Details</h2>
          <div className="mb-5">
            <p>Weight: {weight} kg</p>
            <p>Dimensions: {length}cm x {width}cm x {height}cm</p>
          </div> */}

          <h2 className="text-2xl font-bold text-gray-800 mb-4">Customer Contact</h2>
          <div className="mb-4">
            <input
              type="checkbox"
              id="useDefaultContact"
              checked={formData.useDefaultContact}
              onChange={(e) => setFormData({ ...formData, useDefaultContact: e.target.checked })}
            />
            <label htmlFor="useDefaultContact" className="ml-2 text-sm font-bold text-gray-600">
              Use default contact details
            </label>
          </div>
          {!formData.useDefaultContact && (
            <>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                <input
                  type="text"
                  name="customerContact.name"
                  value={formData.customerContact.name}
                  onChange={handleInputChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Phone</label>
                <PhoneInput
                  country={'us'}
                  value={formData.customerContact.phone}
                  onChange={(phone) => setFormData({
                    ...formData,
                    customerContact: { ...formData.customerContact, phone }
                  })}
                  inputProps={{
                    name: 'customerContact.phone',
                    required: true,
                    className: "w-full p-3 border border-gray-300 rounded-lg"
                  }}
                  inputStyle={{
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    paddingLeft: '50px',
                  }}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                <input
                  type="email"
                  name="customerContact.email"
                  value={formData.customerContact.email}
                  onChange={handleInputChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                />
              </div>
            </>
          )}

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Priority Delivery</label>
            <input
              type="checkbox"
              name="priorityDelivery"
              checked={formData.priorityDelivery}
              onChange={handleInputChange}
              className="mr-2"
            />
          </div>

          <h2 className="text-2xl font-bold text-gray-800 mb-4">Pickup Address</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Full Name <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="pickup.fullName"
              value={formData.pickup.fullName}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.pickupFullName && <p className="text-red-600">{errors.pickupFullName}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Company</label>
            <input
              type="text"
              name="pickup.company"
              value={formData.pickup.company}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Street Address <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="pickup.streetAddress"
              value={formData.pickup.streetAddress}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.pickupStreet && <p className="text-red-600">{errors.pickupStreet}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Address Line 2</label>
            <input
              type="text"
              name="pickup.addressLine2"
              value={formData.pickup.addressLine2}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">City <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="pickup.city"
              value={formData.pickup.city}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.pickupCity && <p className="text-red-600">{errors.pickupCity}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Province <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="pickup.province"
              value={formData.pickup.province}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.pickupProvince && <p className="text-red-600">{errors.pickupProvince}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Postal Code <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="pickup.postalCode"
              value={formData.pickup.postalCode}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.pickupPostal && <p className="text-red-600">{errors.pickupPostal}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number <span className="text-red-500">*</span></label>
            <PhoneInput
              country={'us'}
              value={formData.pickup.phoneNumber}
              onChange={(phone) => setFormData({
                ...formData,
                pickup: { ...formData.pickup, phoneNumber: phone },
              })}
              inputProps={{
                name: 'pickup.phoneNumber',
                required: true,
                className: "w-full p-3 border border-gray-300 rounded-lg"
              }}
              inputStyle={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '4px',
                paddingLeft: '50px',
              }}
            />
            {errors.pickupPhone && <p className="text-red-600">{errors.pickupPhone}</p>}
          </div>

          <h2 className="text-2xl font-bold text-gray-800 mb-4">Dropoff Address</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Full Name <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="dropoff.fullName"
              value={formData.dropoff.fullName}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.dropoffFullName && <p className="text-red-600">{errors.dropoffFullName}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Company</label>
            <input
              type="text"
              name="dropoff.company"
              value={formData.dropoff.company}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Street Address <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="dropoff.streetAddress"
              value={formData.dropoff.streetAddress}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.dropoffStreet && <p className="text-red-600">{errors.dropoffStreet}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Address Line 2</label>
            <input
              type="text"
              name="dropoff.addressLine2"
              value={formData.dropoff.addressLine2}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">City <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="dropoff.city"
              value={formData.dropoff.city}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.dropoffCity && <p className="text-red-600">{errors.dropoffCity}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Province <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="dropoff.province"
              value={formData.dropoff.province}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.dropoffProvince && <p className="text-red-600">{errors.dropoffProvince}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Postal Code <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="dropoff.postalCode"
              value={formData.dropoff.postalCode}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
            {errors.dropoffPostal && <p className="text-red-600">{errors.dropoffPostal}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number <span className="text-red-500">*</span></label>
            <PhoneInput
              country={'us'}
              value={formData.dropoff.phoneNumber}
              onChange={(phone) => setFormData({
                ...formData,
                dropoff: { ...formData.dropoff, phoneNumber: phone },
              })}
              inputProps={{
                name: 'dropoff.phoneNumber',
                required: true,
                className: "w-full p-3 border border-gray-300 rounded-lg"
              }}
              inputStyle={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '4px',
                paddingLeft: '50px',
              }}
            />
            {errors.dropoffPhone && <p className="text-red-600">{errors.dropoffPhone}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Delivery Instructions</label>
            <textarea
              name="deliveryInstructions"
              value={formData.deliveryInstructions}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              rows="4"
            />
          </div>

          <div className="flex justify-between">
            <button type="button" className="bg-gray-400 text-white py-2 px-6 rounded" onClick={() => navigate('/')}>
              Cancel
            </button>
            <button type="submit" className="bg-red-600 text-white py-2 px-6 rounded">
              Get Quote
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default QuotePage;
