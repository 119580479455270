import React from 'react';

function PricingSection() {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Our Pricing</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          
          {/* Price per Kilometer */}
          <div className="p-6 border rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Price per Kilometer</h3>
            <p className="text-gray-600 text-2xl font-bold">$0.50 / km</p>
            <p className="text-gray-500 mt-2">Cost calculated based on the distance to the delivery location.</p>
          </div>

          {/* Price per Kilogram */}
          <div className="p-6 border rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Price per Kilogram</h3>
            <p className="text-gray-600 text-2xl font-bold">$1.00 / kg</p>
            <p className="text-gray-500 mt-2">Cost calculated based on the weight of the package.</p>
          </div>

          {/* Priority Shipping Charge */}
          <div className="p-6 border rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-700 mb-4">Priority Shipping Charge</h3>
            <p className="text-gray-600 text-2xl font-bold">5% surcharge</p>
            <p className="text-gray-500 mt-2">Additional cost for expedited delivery service.</p>
          </div>

        </div>
      </div>
    </section>
  );
}

export default PricingSection;
