import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function GetQuoteCard({ isVisible, onClose }) {
  const [formData, setFormData] = useState({
    weight: '',
    length: '',
    width: '',
    height: '',
  });

  const [errors, setErrors] = useState({
    weight: '',
    length: '',
    width: '',
    height: '',
  });

  const navigate = useNavigate();

  // Handle form input change
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validate form fields
  const validateForm = () => {
    let formValid = true;
    const newErrors = {};

    if (!formData.weight || formData.weight <= 0) {
      newErrors.weight = 'Weight is required and must be a positive number';
      formValid = false;
    }

    if (!formData.length || formData.length <= 0) {
      newErrors.length = 'Length is required and must be a positive number';
      formValid = false;
    }

    if (!formData.width || formData.width <= 0) {
      newErrors.width = 'Width is required and must be a positive number';
      formValid = false;
    }

    if (!formData.height || formData.height <= 0) {
      newErrors.height = 'Height is required and must be a positive number';
      formValid = false;
    }

    setErrors(newErrors);
    return formValid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    console.log('Package Details:', formData);
    
    // Navigate to QuotePage and pass the package details using state
    navigate('/quotePage', { state: formData });
    
    onClose(); // Close modal after submission
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Enter Package Details</h2>
        
        {/* Weight Section */}
        <div className="mb-6 border-b-2 border-gray-200 pb-4">
          <label className="block text-left text-gray-700 text-sm font-bold mb-2" htmlFor="weight">
            Weight (kg) <span className="text-red-600">*</span>
          </label>
          <input
            id="weight"
            type="number"
            name="weight"
            value={formData.weight}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
          {errors.weight && <p className="text-red-600 text-sm">{errors.weight}</p>}
        </div>

        {/* Dimensions Section */}
        <h3 className="text-left text-gray-800 font-semibold mb-4">Dimensions (cm)</h3>

        <div className="mb-4">
          <label className="block text-left text-gray-700 text-sm font-bold mb-2" htmlFor="length">
            Length <span className="text-red-600">*</span>
          </label>
          <input
            id="length"
            type="number"
            name="length"
            value={formData.length}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
          {errors.length && <p className="text-red-600 text-sm">{errors.length}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-left text-gray-700 text-sm font-bold mb-2" htmlFor="width">
            Width <span className="text-red-600">*</span>
          </label>
          <input
            id="width"
            type="number"
            name="width"
            value={formData.width}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
          {errors.width && <p className="text-red-600 text-sm">{errors.width}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-left text-gray-700 text-sm font-bold mb-2" htmlFor="height">
            Height <span className="text-red-600">*</span>
          </label>
          <input
            id="height"
            type="number"
            name="height"
            value={formData.height}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
          {errors.height && <p className="text-red-600 text-sm">{errors.height}</p>}
        </div>

        {/* Form Actions */}
        <div className="flex justify-between mt-6">
          <button
            type="button"
            className="bg-gray-400 text-white py-2 px-6 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-red-600 text-white py-2 px-6 rounded"
            onClick={handleSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

export default GetQuoteCard;
