import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function QuoteDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const { pricing } = location.state || {}; // assuming pricing is passed in location state

  if (!pricing) {
    return <div>No quote available</div>;
  }

  // Create dynamic fields for charges, display if they are not null or undefined
  const charges = [
    { label: 'Base Price', value: pricing.basePrice },
    { label: 'Distance Charge', value: pricing.distanceCharge },
    { label: 'Weight Charge', value: pricing.weightCharge },
    { label: 'Priority Surcharge', value: pricing.prioritySurcharge },
  ];

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-50 to-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">Quote Summary</h2>

        <div className="text-left space-y-4">
          {/* Loop through charges and display only if value is not null */}
          {charges.map((charge, index) => (
            charge.value !== null && charge.value !== undefined && (
              <p key={index} className="text-lg">
                <strong>{charge.label}:</strong> ${charge.value}
              </p>
            )
          ))}

          {/* Display Total Amount if available */}
          {pricing.totalAmount !== null && pricing.totalAmount !== undefined && (
            <p className="text-lg font-bold">
              <strong>Total Amount:</strong> ${pricing.totalAmount}
            </p>
          )}
        </div>

        <button className="mt-6 bg-red-600 text-white py-2 px-6 rounded" onClick={() => navigate('/payment')}>
          Make Payment
        </button>
      </div>
    </div>
  );
}

export default QuoteDetailsPage;
