import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config'; // Import the config file

function OrganizationPage() {
  const [organizationName, setOrganizationName] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [taxID, setTaxID] = useState('');
  const [industry, setIndustry] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactPosition, setContactPosition] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!organizationName || !contactName || !contactPhone) {
      setError('Organization Name, Contact Name, and Contact Phone are required.');
      toast.error('Organization Name, Contact Name, and Contact Phone are required.');
      return;
    }

    // Prepare request payload
    const userId = Cookies.get('userId'); // Get userId from cookies
    const accessToken = Cookies.get('accessToken'); // Get accessToken from cookies

    const requestData = {
      userId: userId,
      name: organizationName,
      registrationNumber,
      taxID,
      industry,
      pointOfContact: {
        name: contactName,
        position: contactPosition,
        email: contactEmail,
        phone: contactPhone,
      },
    };

    // Log the request data
    console.log('Request Body:', requestData);

    try {
      // Send POST request to the backend
      const response = await fetch(`${config.userprofilemanagement.host}:${config.userprofilemanagement.port}${config.userprofilemanagement.base_url}${config.userprofilemanagement.create_organization}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Pass the token in Authorization header
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      // Log the request headers
      console.log('Request Headers:', {
        'Authorization': `Bearer ${accessToken}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      });

      const result = await response.json();
      console.log('API response:', result); // Log the API response

      if (response.ok) {
        toast.success('Profile created successfully!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // Redirect to the homepage
        setTimeout(() => navigate('/'), 3000);
      } else {
        setError(result.message || 'Failed to create profile.');
        toast.error(result.message || 'Failed to create profile.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-50 to-gray-100">
      <ToastContainer />
      <div className="bg-white p-10 rounded-lg shadow-lg max-w-lg w-full" style={{ marginBottom: '60px' }}>
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6" style={{ marginTop: '60px' }}>
          Tell us about your Organization
        </h1>
        
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="organizationName">
              Organization Name
            </label>
            <input
              id="organizationName"
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="registrationNumber">
              Registration Number
            </label>
            <input
              id="registrationNumber"
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="taxID">
              Tax ID
            </label>
            <input
              id="taxID"
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={taxID}
              onChange={(e) => setTaxID(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="industry">
              Industry
            </label>
            <input
              id="industry"
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            />
          </div>

          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Point of Contact
          </h2>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactName">
              Contact Name
            </label>
            <input
              id="contactName"
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactPosition">
              Contact Position
            </label>
            <input
              id="contactPosition"
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={contactPosition}
              onChange={(e) => setContactPosition(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactEmail">
              Contact Email
            </label>
            <input
              id="contactEmail"
              type="email"
              className="w-full p-3 border border-gray-300 rounded-lg"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactPhone">
              Contact Phone Number
            </label>
            <PhoneInput
              country={'us'}
              value={contactPhone}
              onChange={(phone) => setContactPhone(phone)}
              inputProps={{
                name: 'contactPhone',
                required: true,
                className: "w-full p-3 border border-gray-300 rounded-lg"
              }}
              inputStyle={{
                width: '100%', 
                border: '1px solid #ccc',
                borderRadius: '4px',
                paddingLeft: '50px',
              }}
            />
          </div>

          {error && <p className="text-red-600 text-sm mb-4">{error}</p>}

          <button
            type="submit"
            className="w-full bg-red-600 text-white py-3 px-6 rounded-lg font-semibold hover:bg-red-700 transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default OrganizationPage;
