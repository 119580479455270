import React from 'react';
import { Link } from 'react-router-dom';

// The Footer component renders the footer section of the website,
// including links to the privacy policy, terms of service, and contact information.
function Footer() {
  return (
    <footer className="bg-gray-100 py-8">
      <div className="container mx-auto text-center">
        <nav className="space-x-4 mb-4">
          <Link to="/privacy" className="text-gray-600">Privacy Policy</Link>
          <Link to="/terms" className="text-gray-600">Terms of Service</Link>
          <Link to="/contact" className="text-gray-600">Contact Information</Link>
        </nav>
        <p className="text-gray-500">© {new Date().getFullYear()} Maple Pro Courier. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
