import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config'; // Import the config file

function IndividualPage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Helper function to check if the user is an adult
  const isAdult = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    return (age > 19 || (age === 19 && monthDiff >= 0));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!firstName || !lastName || !dateOfBirth || !phone) {
      setError('All fields are required.');
      toast.error('All fields are required.');
      return;
    }

    if (!isAdult(dateOfBirth)) {
      setError('You must be at least 19 years old.');
      toast.error('You must be at least 19 years old.');
      return;
    }

    // Convert dateOfBirth to OffsetDateTime format (ISO string)
    const birthDateISO = new Date(dateOfBirth).toISOString();

    // Prepare request payload
    const userId = Cookies.get('userId'); // Get userId from cookies
    const accessToken = Cookies.get('accessToken'); // Get accessToken from cookies
    const requestData = {
      userId: userId,
      firstName,
      lastName,
      dateOfBirth: birthDateISO,
      phone,
      type: 'client',
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`, // Pass the token in Authorization header
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Request-ID': 'unique-request-id', // Optionally pass any request ID
      }

    console.log('Request Headers:', headers);
    console.log('Request Body:', requestData);

    try {
      // Send POST request to the backend
      const response = await fetch(`${config.userprofilemanagement.host}:${config.userprofilemanagement.port}${config.userprofilemanagement.base_url}${config.userprofilemanagement.create_individual}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestData),
      });

      const result = await response.json();
      console.log('API response:', result);

      if (response.ok) {
        toast.success('Profile updated successfully!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // Redirect to the homepage
        setTimeout(() => navigate('/'), 3000);
      } else {
        setError(result.message || 'Failed to update profile.');
        toast.error(result.message || 'Failed to update profile.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-gray-50 to-gray-100">
      <ToastContainer />
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-6">Tell us more about yourself</h1>
        
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <label className="block text-gray-600 text-sm font-semibold mb-1" htmlFor="firstName">
              First Name
            </label>
            <input
              id="firstName"
              type="text"
              className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:outline-none"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>

          <div className="mb-5">
            <label className="block text-gray-600 text-sm font-semibold mb-1" htmlFor="lastName">
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:outline-none"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>

          <div className="mb-5">
            <label className="block text-gray-600 text-sm font-semibold mb-1" htmlFor="dob">
              Date of Birth
            </label>
            <input
              id="dob"
              type="date"
              className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:outline-none"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              required
            />
          </div>

          <div className="mb-5">
            <label className="block text-gray-600 text-sm font-semibold mb-1" htmlFor="phone">
              Phone Number
            </label>
            <PhoneInput
              country={'us'}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputProps={{
                name: 'phone',
                required: true,
                className: "w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:outline-none"
              }}
              inputStyle={{
                width: '100%', 
                border: '1px solid #ccc',
                borderRadius: '4px',
                paddingLeft: '60px',
              }}
            />
          </div>

          {error && <p className="text-red-600 text-sm mb-5">{error}</p>}

          <button
            type="submit"
            className="w-full bg-red-600 text-white py-4 px-6 rounded-lg font-bold hover:bg-red-700 transition duration-300 focus:ring-4 focus:ring-red-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default IndividualPage;
